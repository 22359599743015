import React from 'react'
import Main from '../../layouts/Main'
import Transition from '../../components/Transition'

const Page404 = () => {
  return (
    <Main>
      <Transition>
        <h1>400</h1>
      </Transition>
    </Main>
  )
}

export default Page404
